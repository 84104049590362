module.exports = {
    "msba": {
        "title": "M.S. in Business Analytics",
        "thumbnail": "/assets/images/thumbnails/businessanalytics.jpg",
        "document": "/assets/docs/MS in Business Analytics.pdf",
        "teaser": "Get more details about **admissions requirements**, the **courses you’ll take**, and your next steps."
    },
    "msds": {
        "title": "M.S. in Data Science",
        "thumbnail": "/assets/images/thumbnails/datascience.jpg",
        "document": "/assets/docs/MS in Data Science.pdf",
        "teaser": "Get more details about **admissions requirements**, the **courses you’ll take**, and your next steps."
    },
}